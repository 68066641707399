import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import { getBotAnimation } from './functions';
import halloween_pumpkin from 'assets/img/animated-icons/halloween_pumpkin.json';
import halloween_bat from 'assets/img/animated-icons/halloween_bat.json';
import halloween_ghost from 'assets/img/animated-icons/halloween_ghost.json';

const LottieBotLazy = ({ type, ...rest }) => {
  const [animationData, setAnimationData] = useState(null);
  const [sideAnimationData, setSideAnimationData] = useState(null);
  const showAnimation = false;

  useEffect(() => {
    const loadAnimation = async () => {
      try {
        const animData = await getBotAnimation(type || '');
        setAnimationData(animData);
      } catch (error) {
        console.error('Error during animation loading:', error);
      }
    };

    const loadSideAnimation = () => {
      const animationArray = [
        halloween_pumpkin,
        halloween_bat,
        halloween_ghost
      ];
      setSideAnimationData(
        animationArray[Math.floor(Math.random() * animationArray.length)]
      );
    };

    loadAnimation();
    loadSideAnimation();
  }, [type]);

  return animationData ? (
    <div style={{ position: 'relative' }}>
      <Lottie animationData={animationData} {...rest} />
      {sideAnimationData && showAnimation && (
        <Lottie
          animationData={sideAnimationData}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '60px',
            height: '60px'
          }}
        />
      )}
    </div>
  ) : (
    <div style={{ height: '120px' }} />
  );
};

LottieBotLazy.propTypes = {
  type: PropTypes.string
};

export default LottieBotLazy;
