export const demoTrackingID = 'cf49cefe38d444dba94d1e8769eb9bfe';

export const demoReferralFriends = [
  {
    created_at: '2024-10-09T13:39:08.893789+00:00',
    status: 'PAID',
    months: 12,
    username: 'giuseppe_90',
    referral_bonus_paid: true
  },
  {
    created_at: '2024-10-09T19:31:32.080714+00:00',
    status: 'PAID',
    months: 1,
    username: 'maria_bianca',
    referral_bonus_paid: false
  },
  {
    created_at: '2024-10-09T20:14:48.368690+00:00',
    status: 'REGISTERED',
    username: 'luca_88'
  },
  {
    created_at: '2024-10-09T09:44:11.760424+00:00',
    status: 'TRIALING',
    months: 12,
    username: 'elena_star'
  },
  {
    created_at: '2024-10-09T20:54:04.896722+00:00',
    status: 'REGISTERED',
    username: 'marco_r'
  }
];
