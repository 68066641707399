import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { ChangeVersionModal as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

export const ChangePWAVersion = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  // Add alert for unsupported PWA
  const [pwaUpdateModalShow, setPwaUpdateModalShow] = useState(false);

  useEffect(() => {
    // Check if running as a standalone PWA
    const isStandalone = window.matchMedia(
      '(display-mode: standalone)'
    ).matches;
    const isIOSStandalone = window.navigator.standalone;

    // Check if device is Android with regex
    const isAndroid = /android/i.test(navigator.userAgent);

    // Parse query string and store parameters in local storage
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const pwaEnv = urlParams.get('pwa_env');
    const pwaVersion = urlParams.get('pwa_version');

    if (pwaEnv) {
      localStorage.setItem('pwa_env', pwaEnv);
    }
    if (pwaVersion) {
      localStorage.setItem('pwa_version', pwaVersion);
    }

    const storedEnv = localStorage.getItem('pwa_env') || 'unknown';
    const storedVersion = localStorage.getItem('pwa_version') || '';
    const SUPPORTED_VERSIONS = {
      android: ['11'],
      ios: []
    };
    const supportedVersions = SUPPORTED_VERSIONS[storedEnv] || [];

    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const lastAlertTime = localStorage.getItem('pwa_alert_last_time');

    if (
      isAndroid && // popup only for Android devices for now
      (isStandalone || isIOSStandalone) &&
      !supportedVersions.includes(storedVersion) &&
      (!lastAlertTime || now - lastAlertTime > oneDay)
    ) {
      setPwaUpdateModalShow(true);
      localStorage.setItem('pwa_alert_last_time', now.toString());
    }
  }, []);

  return (
    <Modal
      show={pwaUpdateModalShow}
      onHide={() => setPwaUpdateModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          {textLang.newVersion[lang]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{textLang.titleAndroid[lang]}</p>
          <p className="text-center">{textLang.downloadNow[lang]}</p>
          <a
            className="d-flex justify-content-center"
            href="https://play.google.com/store/apps/details?id=finance.hodlie.app.twa"
          >
            <img
              width="200px"
              alt={textLang.textAndroid[lang]}
              src={textLang.srcIconAndroid[lang]}
            />
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
          <IconButton
            variant="primary"
            className="me-2 mb-1 "
            icon="times"
            iconAlign="left"
            transform="shrink-3"
            onClick={() => setPwaUpdateModalShow(false)}
          >
            {textLang.close[lang]}
          </IconButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
