const calculateOverallReturn = values => {
  if (values.length === 0) return 0;
  const overallFactor = values.reduce((acc, val) => acc * (val + 1), 1);
  const overallReturn = (overallFactor - 1) * 100;
  return overallReturn;
};

export const calcTotalTimeWeightedReturn = (startValue, events, finalValue) => {
  let previousStartValue = startValue;
  const periodReturns = [];

  for (let i = 0; i < events.length; i++) {
    const event = events[i];

    // Calculate the period return
    let periodReturn =
      (event.preValue - previousStartValue) / previousStartValue;
    periodReturns.push(periodReturn);

    // Update the 'base' value after capital addition
    previousStartValue = event.afterValue;
  }

  // Final return from additional flows to the final value
  let total = 0;
  if (events.length) {
    const lastVal = events[events.length - 1].afterValue;
    let finalPeriodReturn = (finalValue - lastVal) / lastVal;
    periodReturns.push(finalPeriodReturn);
    total = calculateOverallReturn(periodReturns);
  } else total = ((finalValue - startValue) / startValue) * 100;
  return total;
};
