import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dashboard from 'pages/Dashboard';
import BotDetails from 'pages/BotDetails';
import CreateBot from 'pages/CreateBot';
import Login from 'pages/login';
import Signup from 'pages/Signup';
import ConfirmMail from 'pages/Signup/ConfirmMail';
import FAQ from 'pages/FAQ';
import Rewards from 'pages/Rewards';
import Account from 'pages/Account';
import WalletManagement from 'pages/Account/WalletManagement';
import TraderProcedure from 'pages/Account/TraderProcedure';
import Support from 'pages/Support';
import BnbExplanationPage from 'pages/BnbExplanationPage';
import Wallet from 'pages/Wallet';
import VirtualMode from 'pages/VirtualMode';
import ChangePassword from 'pages/ChangePassword';
import ChangeUsername from 'pages/ChangeUsername';
import TermsAndConditions from 'pages/TermsAndConditions';
import TermsOfUse from 'pages/TermsOfUse';
import Sentiment from 'pages/Insights/sentiment/Sentiment';
import BinanceGuide from 'pages/BinanceGuide';
import BinanceDepositGuide from 'pages/BinanceGuide/Deposit';
import BinanceConvertGuide from 'pages/BinanceGuide/Convert';
import Pricing from 'pages/Pricing';
import ForgotPassword from 'pages/ChangePassword/ForgotPassword';
import MailUnsubscribe from 'pages/MailUnsubscribe';
import VirtualExplain from 'pages/VirtualExplain';
import RiskExplain from 'pages/RiskExplain';
import Form from 'pages/Form';
import RiskForm from 'pages/RiskForm';
import Error404 from 'components/errors/Error404';
import AppContext from 'context/Context';

// Use this instead of Route if you want a route protected by login
const PrivateRoute = ({
  component: Component,
  needInitialForm, // if true do the frontend version check for the form
  hasExternalLink = null, // if !== null and user is NOT logged go to an external page
  params, // object, contains all the params of the component
  redirect = null, // if !== null redirect to this path
  ...rest // goes in Route params
}) => {
  const { loggedIn, userInfos } = useContext(AppContext);
  const showStartingForm = false; // if true show the starting form for the frontend_version null user

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <Route
      {...rest}
      render={routeProps =>
        loggedIn ? (
          needInitialForm &&
          userInfos.frontend_version === null &&
          showStartingForm ? (
            <Redirect to="/form" />
          ) : (
            <Component {...routeProps} {...params} />
          )
        ) : hasExternalLink !== null ? (
          <Redirect to={hasExternalLink} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.string,
  needInitialForm: PropTypes.bool,
  hasExternalLink: PropTypes.string,
  redirect: PropTypes.string,
  params: PropTypes.object
};

const HodlieRouter = ({ logStatus }) => {
  const { loggedIn, userInfos } = useContext(AppContext);

  return (
    <Switch>
      {/* PLS manteniamo le Route in ordine alfabetico sul parametro path (tranne '/' e '*') */}
      {/* 
      To pass params to the component and avoid double rendering, use params object like this:
          render={props => <ConfirmMail {...props} />}
      */}
      <PrivateRoute
        path="/"
        exact
        component={Dashboard}
        needInitialForm={true}
        params={{ getInfo: logStatus }}
      />
      <PrivateRoute
        path="/account"
        exact
        component={Account}
        needInitialForm={true}
        params={{ getInfo: logStatus }}
      />
      <PrivateRoute path={'/account/billings'} redirect="/account" />
      <PrivateRoute
        path={'/account/private-procedure'}
        exact
        component={TraderProcedure}
        needInitialForm={true}
        redirect={userInfos.trader ? null : '/account'}
      />
      <PrivateRoute path="/account/profile" redirect="/account" />
      <PrivateRoute path="/account/settings" redirect="/account" />
      <PrivateRoute
        path={'/account/trader-procedure'}
        redirect={'/account/private-procedure'}
      />
      <PrivateRoute
        path="/account/wallet-management"
        exact
        component={WalletManagement}
        needInitialForm={true}
        params={{ realoadUserInfo: logStatus, showCoins: true }}
      />
      {/* <PrivateRoute
        path="/announcements"
        exact
        component={Announcements}
        needInitialForm={true}
      /> */}
      <PrivateRoute
        path="/botdetails/:botName"
        exact
        component={BotDetails}
        needInitialForm={true}
      />
      <PrivateRoute
        path="/bnbdiscountpage"
        exact
        component={BnbExplanationPage}
        needInitialForm={true}
      />
      <Route
        path="/code-change-password"
        component={() => <ChangePassword />}
      />
      <Route path="/change-password" component={() => <ChangePassword />} />
      <Route path="/change-username" component={() => <ChangeUsername />} />
      <Route
        path="/confirm-email"
        exact={true}
        // if there is a token in the url, then we redirect to the confirm mail page
        render={props => <ConfirmMail {...props} />}
      />
      <Route
        path="/connect-binance-wallet"
        component={() => <BinanceGuide />}
      />
      <Route
        path="/convert-on-binance"
        component={() => <BinanceConvertGuide />}
      />
      {/* <PrivateRoute
        path="/createbot"
        exact
        component={CreateBot}
        needInitialForm={true}
      /> */}
      <Route path="/createbot" exact render={() => <Redirect to="/invest" />} />
      <Route path="/dashboard" exact render={() => <Redirect to="/" />} />
      <Route
        path="/deposit-binance"
        component={() => <BinanceDepositGuide />}
      />
      {/* <PrivateRoute
        path="/faq"
        exact
        component={FAQ}
        needInitialForm={true}
        hasExternalLink="/faq-hodlie-ai"
      /> */}
      <Route
        path="/faq"
        exact={true}
        render={() => {
          window.location.href = 'https://support.hodlie.ai/';
        }}
      />
      <Route path="/faq-hodlie-ai" exact={true} component={() => <FAQ />} />
      <Route path="/forgot-password" render={() => <ForgotPassword />} />
      <PrivateRoute
        path="/form"
        exact
        component={Form}
        needInitialForm={false}
      />
      <PrivateRoute
        path="/invest"
        exact
        component={CreateBot}
        needInitialForm={true}
      />
      <Route
        path="/login"
        exact
        render={() =>
          loggedIn ? <Redirect to="/" /> : <Login getInfo={logStatus} />
        }
      />
      <Route
        path="/mail-unsubscribe/:token"
        exact
        component={() => <MailUnsubscribe />}
      />
      <PrivateRoute
        path="/pricing"
        component={Pricing}
        needInitialForm={true}
      />
      <Route
        path="/pricing-hodlie-ai"
        exact={true}
        component={() => <Pricing />}
      />
      <PrivateRoute
        path="/rewards"
        exact
        component={Rewards}
        needInitialForm={true}
      />
      <PrivateRoute
        path="/risk-form"
        exact
        component={RiskForm}
        needInitialForm={false}
      />
      <PrivateRoute
        path="/sentiment"
        exact
        component={Sentiment}
        needInitialForm={true}
      />
      <Route
        path="/signup"
        exact={true}
        component={() => <Signup getInfo={logStatus} />}
      />
      <PrivateRoute
        path="/support"
        exact
        component={Support}
        hasExternalLink="/support-hodlie-ai"
        needInitialForm={true}
      />
      <Route
        path="/support-hodlie-ai"
        exact={true}
        component={() => <Support />}
      />
      <Route
        path="/termsandconditions"
        exact={true}
        render={() => <TermsAndConditions />}
      />
      <Route path="/termsofuse" exact={true} render={() => <TermsOfUse />} />
      {/* <Route path="/top-scorers" exact={true} render={() => <TopScorers />} /> */}
      {/* <PrivateRoute
        path="/usage-guide"
        exact
        component={UsageGuide}
        needInitialForm={true}
        hasExternalLink="/usage-guide-hodlie-ai"
      /> */}
      <PrivateRoute
        path="/virtual-explain"
        exact
        component={VirtualExplain}
        needInitialForm={true}
      />
      <PrivateRoute
        path="/risk-explain"
        exact
        component={RiskExplain}
        needInitialForm={true}
      />
      <PrivateRoute
        path="/virtual-mode"
        exact
        component={VirtualMode}
        needInitialForm={true}
        params={{ realoadUserInfo: logStatus }}
      />
      {/* <Route
        path="/usage-guide-hodlie-ai"
        exact={true}
        component={() => <UsageGuide />}
      /> */}
      <PrivateRoute
        path="/wallet"
        exact
        component={Wallet}
        needInitialForm={true}
        params={{ realoadUserInfo: logStatus }}
      />
      <Route path="*" exact={true} component={Error404} />
    </Switch>
  );
};

HodlieRouter.propTypes = {
  logStatus: PropTypes.func
};

export default HodlieRouter;
